import React from "react"
import Layout from "../component/Layout"
import "../styles/events.scss"
import SEO from "../component/SEO"

const events = () => {
  return (
    <Layout>
      <SEO title="Events" />
      <section className="hero-content events">
        <div className="grid"></div>
        <div id="hero-title-events" className="hero-title">
          <div className="inner-wrap">
            <h1>Events @ Dorlands</h1>
            <h2>South Wales - Just outside Chepstow</h2>
          </div>
        </div>
      </section>
      <section className="banner">
        <h4>Celebrate, Collaborate and Create At Dorlands</h4>
      </section>
      <section id="hen-party" className="hen-party ">
        <div className="grid">
          <div className="cell-1">
            <div className="top_text">
              <h4>Hen Weekends</h4>
              <p>
                Dorlands House is the perfect setting to celebrate your hen
                weekend in comfort and style. It has become a very popular place
                for hen’s to come and enjoy a weekend away in the countryside,
                whilst also being close to Bristol if you fancy some nightlife!
              </p>
              <p>
                It is self catering so you can pre-order a food delivery if you
                like, you can also arrange a chef to come and cook for you
                whilst you are soaking in the hot tub and enjoying the
                spectacular views of the Usk Valley, known as Uskany!
              </p>
              <p>
                We are only 5 minutes from Chepstow Racecourse if you fancy a
                day at the races or a day of pampering at St Pierre Country
                Club. You are very welcome to arrange your own beautician to
                come to the house.
              </p>
              <p>
                Whatever you need to make the ‘bride to be’ feel extra special,
                the team at Dorlands House are here to help!
              </p>
            </div>
          </div>
          <div className="cell-2 hen-pic">
            {/* <!--CELL TWO HAS A PICTURE--> */}
          </div>
        </div>
      </section>
      <section id="celebrate" className="celebrate">
        <div className="grid">
          <div className="cell-2 celebration-pic">
            {/* <!--CELL TWO HAS A PICTURE--> */}
          </div>
          <div className="cell-1">
            <div className="top_text">
              <h4>Family Celebrations</h4>

              <p>
                Come and celebrate and mark the occasion at Dorlands, bring all
                the family and relax knowing there is enough space to enjoy the
                weekend together. We are perfectly located for people coming
                from all across the country.
              </p>
              <p>
                You can have a food delivery pre arranged or have a chef come
                and cook and treat you all, whatever you would like, we can help
                arrange it. There is something for all ages in the local area of
                outstanding natural beauty.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section id="occasions" className="occasions">
        <div className="grid">
          <div className="cell-1">
            <div className="top_text">
              <h4>Special Occasions</h4>

              <p>
                What better way to celebrate than with your friends all together
                in a home from home setting with all the space you need for
                everyone to come! Choose between each house or book them both!
                Request a hot tub, organise catering, games, and so much more.
              </p>

              <p>
                Please get in touch to discuss any ideas and we will help make
                them a reality.
              </p>
            </div>
          </div>
          <div className="cell-2 ocassions-pic">
            {/* <!--CELL TWO HAS A PICTURE--> */}
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default events
